<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('title.myRelease') }}</div>
			</div>
			<div class="filter">
				<div class="time">
					<span class="label">{{ $t('submitTime') }}:</span>
					<el-date-picker value-format="yyyy-MM-dd" v-model="timeValue" type="daterange"
						:range-separator="$t('to')" :start-placeholder="$t('startTime')" :end-placeholder="$t('endTime')"
						@change="dateChange">
					</el-date-picker>
				</div>
				<div class="status">
					<span class="label">{{ $t('state') }}:</span>
					<el-select v-model="check" :placeholder="$t('release.pleaseSelect')" clearable @change="selectChange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="btn">
					<div class="search" @click="searchClick">{{ $t('search') }}</div>
					<div class="reset" @click="resetClick">{{ $t('reset') }}</div>
				</div>
			</div>


			<div class="table">
				<el-table :data="messageList" border style="width: 100%" :header-cell-style="{ background: '#F6F7F9', }">
					<!-- <el-table-column type="index" label="" align="center">
					</el-table-column> -->
					<el-table-column prop="date" :label="$t('releaseContent')" width="403" align="center">
						<template slot-scope="scope">
							<div class="infor">
								<div class="infor_img">
									<img :src="scope.row.thumb[0]">
								</div>
								<div class="infor_title">
									{{ scope.row.title }}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="create_time" :label="$t('applyTime')" align="center">
					</el-table-column>
					<el-table-column :label="$t('auditTime')" align="center">
						<template slot-scope="scope">
							<div class="status">
								<span class="blue" v-if="scope.row.check_time != 0">{{ scope.row.check_time }}</span>
								<span class="blue" v-else>-</span>
							</div>
							<!-- <div class="infor_title">
								{{ scope.row.look_count }}
							</div> -->
						</template>
					</el-table-column>
					<el-table-column :label="$t('state')" align="center" width="140">
						<template slot-scope="scope">
							<div class="status">
								<!-- check 1审核中 2通过 3拒绝 4过期-->
								<span class="blue" v-if="scope.row.check == 1">{{ $t('my.checkIn') }}</span>
								<span class="gray" v-if="scope.row.check == 2">{{ $t('my.applySuccess') }}</span>
								<span class="red" v-if="scope.row.check == 3">{{ $t('my.applyFailure') }}</span>
								<span class="red" v-if="scope.row.check == 4">{{ $t('my.expired') }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="remark" :label="$t('reason')" align="center">
					</el-table-column>
					<!-- <el-table-column :label="$t('isStop')" align="center">
						<template slot-scope="scope">
							<el-switch v-if="scope.row.check == 2" @change="switchChange($event, scope.row.id)"
								active-color="#ff0000" inactive-color="#eeeeee" v-model="scope.row.status">
							</el-switch>
						</template>
					</el-table-column> -->

					<el-table-column :label="$t('operation')" align="center" width="288">
						<template slot-scope="scope">
							<div class="operate">
								<!-- 查看详情 -->
								<span class=" btn"
									@click="toApplyReleaseDetail(scope.row.id, scope.row.check, scope.row.url)">{{
										$t('checkDetail') }}</span>

								<!-- <el-popconfirm :title="$t('my.deleteAllContent')" @confirm="deleteRelease(scope.row.id)">
									<span class=" btn" slot="reference">{{ $t('delete') }}</span>
								</el-popconfirm> -->
								<!-- 删除 -->
								<span class="btn" @click="dialogShow(scope.row.id)"
									v-if="scope.row.check == 3 || scope.row.check == 4">{{
										$t('delete') }}</span>

								<!-- 编辑 -->
								<span class=" btn" v-if="scope.row.check == 2"
									@click="goEdit(scope.row.id, scope.row.end_status, scope.row.end_time)">{{
										$t('my.edit') }}</span>

								<!-- 仅自己可见 -->
								<span class=" btn" v-if="scope.row.check == 2 && scope.row.status == 1"
									@click="isMySee(scope.row.id)">{{
										$t('onlySee') }}</span>

								<!-- 所有人可见 -->
								<span class=" btn" v-if="scope.row.check == 2 && scope.row.status == 0"
									@click="isMySee(scope.row.id)">{{
										$t('allSee') }}</span>

								<!-- 添加水印 -->
								<span class=" btn" v-if="scope.row.check == 2 && scope.row.marcas == 0"
									@click="showadd(scope.row.id)">{{
										$t('addWatermark') }}</span>

								<!-- 重新提交 -->
								<span class=" btn" @click="resubmitRelease(scope.row.id, scope.row.end_status)"
									v-if="scope.row.check == 3 || scope.row.check == 4">{{
										$t('submitAgain') }}</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>



			<div class="pagination">
				<el-pagination background layout="prev, pager, next,sizes" :total="total"
					@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
					:page-size="pageSize" background:true popper-class="select_bottom" :page-sizes="pageSizes">
				</el-pagination>
			</div>
		</div>

		<!-- 删除弹窗 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="30%">
			<div slot="title">
				<div class="title_all">
					<div>{{ $t('my.tips') }}</div>
				</div>
			</div>
			<span>{{ $t('querenDel') }}？</span>
			<span slot="footer" class="dialog-footer" style="margin-top: 20px;">
				<el-button @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
				<el-button type="primary" @click="deleteRelease(del_id)">{{ $t('confirm') }}</el-button>
			</span>
		</el-dialog>


		<!-- 水印弹窗 -->
		<el-dialog title="" :visible.sync="watermarkVisible" width="30%">
			<div slot="title">
				<div class="title_all">
					<div>{{ $t('addWatermark') }}</div>
				</div>
			</div>
			<span>{{ $t('shuiYin') }}</span>
			<span slot="footer" class="dialog-footer" style="margin-top: 20px;">
				<el-button @click="addWatermark(1)">{{ $t('chushou') }}</el-button>
				<el-button type="primary" @click="addWatermark(2)">{{ $t('chuzu') }}</el-button>
			</span>
		</el-dialog>




	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [{
				value: 0,
				label: this.$t('other.all')
			}, {
				value: 1,
				label: this.$t('other.applyIn')
			}, {
				value: 3,
				label: this.$t('other.applyFailure')
			}, {
				value: 2,
				label: this.$t('other.applySuccess')
			}],

			timeValue: '',
			startTime: '', //开始日期
			endTime: '', //结束日期
			check: 0, //选择的类型
			messageList: [], //信息列表
			total: 8, //总条数
			currentPage: 1, //当前页数
			pageSize: 8, //每页的条数
			pageSizes: [8, 10, 20, 30, 40, 50, 100],
			dialogVisible: false,// 删除弹框
			del_id: '',
			watermarkVisible: false,//水印弹窗
			infoId: '', //信息id
		}
	},
	mounted() {
		this.msgList();
	},

	activated() {
		this.msgList();
	},
	methods: {

		// 是否停用
		switchChange(val, id) {
			this.$http.isStopMessage({
				id,
			}).then(res => {
				if (res.code == 1) {
					// this.msgList();
				} else {
					this.$message.info(res.msg);
				}
			})
		},
		// 获取列表
		msgList() {
			let data = {
				startTime: this.startTime,
				endTime: this.endTime,
				pageNo: this.currentPage,
				pageSize: this.pageSize,
				check: this.check,
			}
			this.$http.msgList(data).then(res => {
				if (res.code == 1) {
					this.messageList = res.data.data;
					this.messageList = this.messageList.map(item => {
						return {
							...item,
							status: item.status == 1 ? false : true,
						}
					})
				} else {
					this.$message.info(res.msg);
				}

				console.log(this.messageList, '获取分类信息');

			})
		},
		// 下拉框筛选
		selectChange(e) {
			this.check = e;
		},
		// 时间选择器事件
		dateChange(e) {
			console.log(e);
			this.startTime = e[0];
			this.endTime = e[1];
		},
		currentChangeClick(currentPage) {
			this.currentPage = currentPage;
			this.msgList();

		},
		sizeChangeClick(pageSize) {
			this.pageSize = pageSize;
			this.msgList();
		},
		//搜索
		searchClick() {
			this.msgList();
		},
		//重置
		resetClick() {
			this.timeValue = '';
			this.check = 0;
			this.startTime = '';
			this.endTime = '';
			this.msgList();
		},
		// 查看详情
		toApplyReleaseDetail(id, check, url) {
			if (url == 1) {
				this.$router.push({
					path: '/particulars',
					query: {
						id: id,
						check: check,
					}
				})
			} else if (url == 2) {
				this.$router.push({
					path: '/particulars',
					query: {
						id: id,
						check: check,
					}
				})
			} else {
				// 跳转对应详情页
				this.$router.push({
					path: '/details',
					query: {
						id: id,
						check: check,
					}
				})
			}

		},

		dialogShow(id) {
			this.dialogVisible = true
			this.del_id = id
		},
		// 删除
		deleteRelease(information_id) {
			console.log(information_id);
			this.$http.deleteInformation({
				information_id: information_id,
			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.dialogVisible = false
					this.msgList();
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		// 重新提交
		resubmitRelease(information_id, end_status) {
			console.log("pp");
			this.$router.push({
				path: '/release',
				query: {
					information_id: information_id,
					end_status: end_status
				}
			})
		},

		// 编辑
		goEdit(id, status, time) {
			this.$router.push({
				path: '/release',
				query: {
					information_id: id,
					end_status: status,
					end_time: time
				}
			})
		},

		// 仅自己可见
		isMySee(id) {
			this.$http.isStopMessage(
				{
					id: id
				}
			).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg)
					this.msgList()
				} else {
					this.$message.info(res.msg)
				}
			})
		},

		// 打开水印弹窗
		showadd(id) {
			this.watermarkVisible = true
			this.infoId = id
		},

		// 添加水印
		addWatermark(text) {
			this.$http.addWatermark(
				{
					id: this.infoId,
					text: text
				}
			).then(res => {
				if (res.code == 1) {
					console.log(res);
					this.$message.success(res.msg)
					this.watermarkVisible = false
					this.msgList()
				} else {
					this.$message.error(res.msg)
				}
			})
		}


	},

}
</script>

<style scoped lang="less">
.person_infor {
	width: 100%;
}

.login_top {
	display: flex;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;

	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
	}
}

.filter {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 16px 0 32px;
	padding-left: 24px;

	.label {
		margin-right: 12px;
	}

	.time {
		margin-right: 32px;
		padding-bottom: 16px;

		/deep/ .el-date-editor {
			width: 380px;
		}

		/deep/ .el-date-editor .el-range-separator {
			width: 10% !important;
		}
	}

	.status {
		margin-right: 32px;
		padding-bottom: 16px;

		/deep/ .el-input__inner {
			width: 150px;
		}
	}

	.btn {
		display: flex;
		padding-bottom: 16px;

		&:hover {
			cursor: pointer;
		}

		.search {
			width: 80px;
			height: 36px;
			background: rgba(243, 152, 0);
			border-radius: 4px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			line-height: 36px;
			color: #FFFFFF;
			margin-right: 24px;

		}

		.reset {
			width: 80px;
			height: 36px;
			background: #F6F7F9FF;
			border-radius: 4px;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			line-height: 36px;
			color: #333333FF;
		}
	}
}



.table {
	padding: 0 24px;

	.infor {
		display: flex;
		// justify-content: center;
		align-items: center;

		.infor_img {
			img {
				width: 48px;
				height: 48px;
			}
		}

		.infor_title {
			margin-left: 8px;
			width: 132;
			height: 45px;
			color: #333333;
			font-weight: 500;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
			word-break: break-all;
			word-wrap: break-word;
		}
	}

	.status {
		font-size: 12px;

		&>span:hover {
			cursor: pointer;
		}

		.red {
			color: #F39800;
		}

		.blue {
			color: #4177DD;
		}

		.gray {
			color: #999999;
		}

	}

	.operate {
		padding: 10px 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		flex: 1;
		height: 112px;
		box-sizing: border-box;

		span {
			font-size: 12px;
			display: inline-block;

			&:hover {
				cursor: pointer;
			}
		}

		.btn {
			min-width: 88px;
			height: 32px;
			background: #F6F7F9;
			border: 1px solid #DDDDDD;
			border-radius: 4px;
			line-height: 32px;
			margin-bottom: 2px;
			box-sizing: border-box;

		}

		// .blue {
		// 	color: #4177DD;
		// }

		// .gray {
		// 	margin-left: 8px;
		// 	color: #999999;
		// }
	}
}

.pagination {
	margin: 32px 0 32px;
	display: flex;
	justify-content: flex-end;
}

/deep/ .el-table .cell {
	text-overflow: clip !important;
	word-break: normal;
}

/deep/ .el-dialog {
	width: 490px;
	height: 280px;
	border-radius: 8px;
}

/deep/ .el-dialog__header {
	display: flex;
	justify-content: left;
	align-items: center;
	padding: 0 32px !important;
	height: 70px;
	font-size: 20px;
	font-family: Microsoft YaHei;
	font-weight: bold;
}

/deep/ .el-button--primary {
	width: 200px;
	height: 40px;
}

/deep/ .el-button {
	width: 200px;
	height: 40px;
}

/deep/.el-dialog__footer {
	padding: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

/deep/.el-dialog__body {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	font-size: 20px;
	padding: 0 !important;
	height: 130px;

}
</style>
